<template>
  <div class="form-group">
    <div>
      <label>{{ t("components.visible_to_customers_selector.label") }}</label>
    </div>
    <div class="btn-group btn-multiple">
      <div class="btn btn-primary" :class="{ active: !value }" @click="updateValue(null)">
        {{ t("components.visible_to_customers_selector.all_customers") }}
      </div>
      <div class="btn btn-primary" :class="{ active: value == 'standard' }" @click="updateValue('standard')">
        {{ t("components.visible_to_customers_selector.standard_customers") }}
      </div>
      <div class="btn btn-primary" :class="{ active: value == 'limited' }" @click="updateValue('limited')">
        {{ t("components.visible_to_customers_selector.limited_customers") }}
      </div>
      <div class="btn btn-primary" :class="{ active: value == 'selected' }" @click="updateValue('selected')">
        {{ t("components.visible_to_customers_selector.selected_customers") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";

defineProps<{ value?: string }>();

const emit = defineEmits(["input"]);

const { t } = useI18n();

const updateValue = (value: string) => {
  emit("input", value);
};
</script>
