<template>
  <div class="share-button-container">
    <uiv-dropdown class="share-button-dropdown">
      <report-action-button
        v-uiv-tooltip.hover="tooltip"
        icon="fluent:share-24-filled"
        :text="$t('actions.share')"
      ></report-action-button>
      <template #dropdown>
        <div class="share-form" v-if="!link">
          <spinner></spinner>
        </div>
        <div class="share-form" v-else @click.stop>
          <b>{{ $t("public_share.title") }}</b>
          <div class="mt-md">
            <div class="input-group">
              <span class="input-group-btn">
                <confirm-button
                  class="btn btn-primary"
                  :title="$t('public_share_regenerate_link')"
                  tag="button"
                  :popover-message="$t('public_share.regenerate_link_confirm_message')"
                  @confirm="regenerateLink()"
                  ><i class="fa fa-refresh"></i></confirm-button
              ></span>
              <input class="form-control link-input" ref="textInput" type="text" :value="shareableLink" readonly /><span
                class="input-group-btn"
              >
                <button class="btn btn-primary" @click="copyLink()">{{ $t("actions.copy") }}</button></span
              >
            </div>
          </div>
          <div class="mt-md flex-row justify-content-space-between align-items-center">
            <label class="refresh">
              <input type="checkbox" v-model="refresh" />{{ $t("public_share.refresh_automatically") }}
            </label>
            <dropdown-select
              :value="language"
              :available-items="languageOptions"
              @update="onLanguageChange"
            ></dropdown-select>
          </div>
          <div class="mt-md">
            <a class="cursor-pointer" @click="openFilteredLinksModal()">{{
              $t("public_share.generate_filtered_links")
            }}</a>
          </div>
        </div>
      </template>
    </uiv-dropdown>
    <filtered-links-modal ref="filteredLinksModal" :report="report" :my-report="myReport"></filtered-links-modal>
  </div>
</template>

<script lang="ts">
import MyReport from "../../model/my-report";
import Report from "../../model/report";
import Vue, { defineComponent, PropType } from "vue";
import ConfirmButton from "../confirm-button.vue";
import Spinner from "../spinner.vue";
import toastr from "toastr";
import i18n from "../../i18n";
import SvgIcon from "../svg-icon.vue";
import DropdownSelect from "../dropdown-select.vue";
import { makeApiInstance } from "../../api/instance";
import FilteredLinksModal from "./filtered-links-modal.vue";
import reportActionButton from "@/components/report-action-button.vue";

export default defineComponent({
  components: {
    ConfirmButton,
    Spinner,
    SvgIcon,
    DropdownSelect,
    FilteredLinksModal,
    reportActionButton,
  },
  data() {
    return {
      refresh: true,
      link: null,
      language: "",
    };
  },
  computed: {
    shareableLink() {
      return (
        this.link +
        (this.refresh ? "?refresh=1" : "") +
        (this.language && this.language != "" ? `&locale=${this.language}` : "")
      );
    },
    tooltip() {
      return i18n.t("actions.share");
    },
    languageOptions() {
      return [
        { id: "", name: i18n.t("activerecord.attributes.company.company_language") },
        ...window.zoinedContext.available_languages.map(({ code, name }) => ({
          id: code,
          name,
        })),
      ];
    },
    filteredLinksModal: {
      cache: false,
      get() {
        return this.$refs["filteredLinksModal"] as FilteredLinksModal;
      },
    },
    textInput: {
      cache: false,
      get() {
        return this.$refs["textInput"] as HTMLInputElement;
      },
    },
  },
  created() {
    this.fetchLink();
  },
  methods: {
    onLanguageChange(language) {
      this.language = language;
    },
    regenerateLink() {
      this.link = null;
      this.fetchLink(true);
    },
    copyLink() {
      navigator.clipboard.writeText(this.textInput.value);
      toastr.success(i18n.t("public_share.link_copied_to_clipboard"));
    },
    fetchLink(regenerate = false) {
      const url = `/api/v1/reports/${this.report.id}/public_link`;
      const params: any = {};
      if (this.myReport?.id) {
        params.my_report_id = this.myReport.id;
      }
      if (regenerate) {
        params.regenerate = "1";
      }
      makeApiInstance()
        .get(url, { params })
        .then((response) => response.data)
        .then(({ link }) => (this.link = link));
    },
    openFilteredLinksModal() {
      this.filteredLinksModal.show();
    },
  },
  props: {
    report: {
      type: Object as PropType<Report>,
    },
    myReport: {
      type: Object as PropType<MyReport>,
    },
  },
});
</script>

<style lang="scss" scoped>
.share-button {
  outline: 0px !important;
  svg {
    width: 18px;
  }
}
.share-form {
  min-height: 50px;
  padding: 10px 20px;

  input.link-input {
    font-size: 12px;
    max-width: 100%;
  }
}
label.refresh {
  display: flex;
  margin-bottom: 0;
  input {
    margin-top: 0;
    margin-right: 5px;
  }
  font-weight: normal;
}
</style>
<style lang="scss">
@media (min-width: 768px) {
  .share-form {
    width: 450px;
  }
}
@media (max-width: 767px) {
  .share-form {
    width: 100%;
  }
  .share-button-dropdown {
    .dropdown-menu {
      position: fixed;
      top: 50%;
      margin: 0 10px;
      transform: translateY(-50%);
    }
  }
}
</style>
