<template>
  <pill-list
    :title="$t('activerecord.models.user.other')"
    :typeahead="true"
    :items="items"
    :available-items="menuItems"
    @update="onUpdate($event)"
    @search="fetchUsers($event)"
  ></pill-list>
</template>

<script lang="ts">
import Vue, { defineComponent, PropType } from "vue";
import PillItem from "../interfaces/pill-item";
import MenuItem from "../interfaces/menu-item";
import pillList from "./pill-list.vue";
import UsersApiService from "../api/users-api-service";
export default defineComponent({
  components: {
    pillList,
  },
  data() {
    return {
      menuUsers: [],
    };
  },
  computed: {
    items(): PillItem[] {
      return this.roles.map(({ id, name }) => ({
        value: id,
        name,
      }));
    },
    menuItems(): MenuItem[] {
      return this.menuUsers.map(({ id, first_name, last_name }) => ({
        key: id,
        name: `${first_name} ${last_name}`,
      }));
    },
  },
  methods: {
    fetchUsers(search) {
      this.menuUsers = [];
      new UsersApiService().getUsers({ search }).then((users) => (this.menuUsers = users));
    },
    onUpdate({ items }) {
      const roles = items.map(({ value, name }) => ({
        id: value,
        name,
      }));
      this.$emit("update", roles);
    },
  },
  props: {
    roles: { default: [], type: Object as PropType<Array<any>> },
  },
});
</script>
