<template>
  <div class="report-body">
    <active-filters-panel
      :filter-configuration="flatFilterConfiguration"
      :chart-options="chartOptions"
      @update="filterConfigurationUpdated"
    ></active-filters-panel>
    <zoined-report
      class="mt-xl"
      v-if="components"
      :components="components"
      :filter-configuration="flatFilterConfiguration"
      :chart-options="chartOptions"
      :table-config="tableConfig"
      :highchart-options="highchartOptions"
      :custom="report.custom"
      :dashboard="myReport.dashboard"
      :editable="myReport.dashboard"
      @filter-configuration-updated="filterConfigurationUpdated"
      @chart-options-updated="updateChartOptions"
      @table-config-updated="updateTableConfig"
      @highchart-options-updated="updateHighchartOptions"
      @components-updated="componentsUpdated"
      @excel-export-component="excelExportComponent"
      @edit="editComponent"
    ></zoined-report>
    <div class="help-block" v-if="report.config.help_key">
      <i class="fa fa-info-circle mr-sm"></i><span>{{ $t("report.help." + this.report.config.help_key) }}</span>
    </div>
    <add-component-modal
      ref="addComponentModal"
      :setComponent="updateComponent"
      :filter-configuration="flatFilterConfiguration"
      :shared="isShared"
    ></add-component-modal>
  </div>
</template>

<script lang="ts">
import FilterConfiguration from "../model/filter-configuration";
import Vue, { defineComponent } from "vue";
import ReportComponent from "../model/report-component";
import _ from "lodash";
import { configToActive, filterToFlyover, flatFilterConfiguration, normalizeConfig } from "../lib/filter-util";
import printableFilters from "../filters/printable-filters.vue";
import zoinedReport from "./zoined-report.vue";
import { exportReport } from "../lib/export";
import TableConfig from "../model/table-config";
import ChartOptions from "../model/chart-options";
import Mutations from "../store/mutations";
import Report from "../model/report";
import MyReport from "../model/my-report";
import ReportContext from "../model/report-context";
import ReportActions from "../components/report-actions.vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import AnalyticsApiService from "../api/analytics-api-service";
import MyReportsApiService from "../api/my-reports-api-service";
import Actions from "../store/actions";
import { Base64 } from "js-base64";
import { availableComponents } from "../custom-dashboard-editor/component-types";
import { reportTypes } from "../custom-report/report-types";
import ReportType from "../model/report-type";
import { makeApiInstance } from "../api/instance";
import ActiveFiltersPanel from "@/components/active-filters/active-filters-panel.vue";
import AddComponentModal from "@/custom-dashboard-editor/add-component-modal.vue";

export default defineComponent({
  components: {
    printableFilters,
    zoinedReport,
    ReportActions,
    ActiveFiltersPanel,
    AddComponentModal,
  },
  data() {
    const components: ReportComponent[] = null;
    const initialConfiguration: {
      filterConfiguration: FilterConfiguration;
      chartOptions: ChartOptions;
      tableConfig: TableConfig;
      highchartOptions: any;
    } = null;

    return {
      initialConfiguration,
      components,
    };
  },
  computed: {
    reportContext(): ReportContext {
      return this.$store.getters.getReportContext;
    },
    chartOptions(): ChartOptions {
      return this.reportContext?.chart_options;
    },
    filterConfiguration(): FilterConfiguration {
      return this.reportContext?.filter_configuration;
    },
    tableConfig(): TableConfig {
      return this.reportContext?.table_config;
    },
    highchartOptions() {
      return this.reportContext?.highchart_options;
    },
    zoinedContext() {
      return window.zoinedContext;
    },
    flatFilterConfiguration() {
      return this.filterConfiguration && flatFilterConfiguration(this.filterConfiguration);
    },
    companyReport() {
      return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
    },
    partnerReport() {
      return !!this.myReport.partner_id;
    },
    isShared() {
      return this.companyReport || this.partnerReport;
    },
    filters() {
      return (
        this.flatFilterConfiguration && {
          ...configToActive(this.flatFilterConfiguration),
          currency: [this.zoinedContext.currency.currency],
        }
      );
    },
    report(): Report {
      return this.reportContext.report;
    },
    myReport(): MyReport {
      return this.reportContext.my_report;
    },
    reportData() {
      const config = {
        ..._.omit(this.report.config, "filterConfiguration"),
        components: this.components.map((component) => _.omit(component, ["data"])),
      };
      return {
        report: {
          config: JSON.stringify(config),
        },
        my_report: {
          control_state: this.chartOptions,
          filters: this.filterConfiguration,
          table_config: this.tableConfig,
          highchart_options: this.highchartOptions,
        },
      };
    },
    hasChanges() {
      return this.$store.getters.hasChanges;
    },
    reportConfiguration() {
      return {
        filterConfiguration: this.filterConfiguration,
        chartOptions: this.chartOptions,
        tableConfig: this.tableConfig,
        highchartOptions: this.highchartOptions,
      };
    },
    reportTypesByName() {
      return reportTypes.reduce(
        (result, reportType) => ({
          ...result,
          [reportType.config.report_type]: reportType,
        }),
        {}
      );
    },
    reportTypeName() {
      return this.report.config.report_type;
    },
    reportType(): ReportType {
      if (this.reportTypeName) {
        return this.reportTypesByName[this.reportTypeName];
      } else {
        return {
          config: this.report.config,
        };
      }
    },
    addComponentModal: {
      cache: false,
      get() {
        return this.$refs["addComponentModal"] as typeof AddComponentModal;
      },
    },
  },
  created() {
    this.components = this.report.config.components;
    if (this.myReport.dashboard) {
      this.components = this.components.map((component: any) => {
        // ensure that component has latest configuration
        const config = availableComponents[component.name] || availableComponents[component.type];
        return { ...config, ...component };
      });
    } else if (this.report.custom) {
      this.components = [...this.reportType.config.components];
    }

    this.initialConfiguration = this.reportConfiguration;

    EventBus.subscribe(Events.saveReport, this.saveReport);
    EventBus.subscribe(Events.editReport, this.editReport);
    EventBus.subscribe(Events.duplicateReport, this.duplicateReport);
    EventBus.subscribe(Events.setAsDefault, this.setAsDefault);
    EventBus.subscribe(Events.deleteReport, this.deleteReport);
  },
  destroyed() {
    EventBus.unsubscribe(Events.saveReport, this.saveReport);
    EventBus.unsubscribe(Events.editReport, this.editReport);
    EventBus.unsubscribe(Events.duplicateReport, this.duplicateReport);
    EventBus.unsubscribe(Events.setAsDefault, this.setAsDefault);
    EventBus.unsubscribe(Events.deleteReport, this.deleteReport);
  },
  methods: {
    editReport() {
      if (this.myReport.dashboard) {
        this.$router.push({ name: "edit_custom_dashboard", params: { id: this.report.id.toString() } });
      } else if (this.report.custom) {
        this.$router.push({ name: "edit_custom_report", params: { id: this.report.id.toString() } });
      } else {
        this.$router.push({
          name: "edit_report",
          params: { name: this.report.name, myReportId: this.myReport.id.toString() },
        });
      }
    },
    async deleteReport() {
      await new MyReportsApiService().delete(this.myReport);

      if (window.zoinedContext.newLayout) {
        this.$store.dispatch(Actions.fetchNavigation);
        this.$router.replace({ name: "root" });
      } else {
        location.href = "/";
      }
    },
    saveReport() {
      this.$store.commit(Mutations.setHasChanges, false);
      this.initialConfiguration = this.reportConfiguration;

      if (this.report.custom) {
        return makeApiInstance().put(`/api/v1/reports/${this.report.id}`, this.reportData);
      } else {
        return new AnalyticsApiService().update(this.myReport.id, {
          control_state: this.chartOptions,
          filters: this.filterConfiguration,
          table_config: this.tableConfig,
          highchart_options: this.highchartOptions,
        } as MyReport);
      }
    },
    duplicateReport() {
      const configJson = JSON.stringify(
        _.pick(this.reportContext, "filter_configuration", "chart_options", "table_config")
      );
      const config = Base64.encode(configJson);

      if (this.myReport.dashboard) {
        this.$router.push({ name: "new_custom_dashboard", query: { duplicateId: this.report.id.toString(), config } });
      } else if (this.report.custom) {
        this.$router.push({ name: "new_custom_report", query: { duplicateId: this.report.id.toString(), config } });
      } else {
        const query = this.myReport.id ? { duplicateId: this.myReport.id.toString(), config } : { config };
        this.$router.push({
          name: "new_report",
          query,
        });
      }
    },
    async setAsDefault() {
      await makeApiInstance().post(`/api/v1/reports/default_dashboard?id=${this.report.id}`);
      this.$store.commit(Mutations.setDefaultDashboardId, this.report.id);
    },
    updateChartOptions(chartOptions) {
      this.$store.commit(Mutations.setChartOptions, chartOptions);
    },
    updateHighchartOptions(highchartOptions) {
      this.$store.commit(Mutations.setHighchartOptions, highchartOptions);
    },
    filterConfigurationUpdated(filterConfiguration) {
      const flyoverFilterConfiguration = filterToFlyover(filterConfiguration);
      this.$store.commit(Mutations.setFilterConfiguration, {
        ...flyoverFilterConfiguration,
        filters: normalizeConfig(flyoverFilterConfiguration.filters),
      });
    },
    componentsUpdated(components) {
      if (!_.isEqual(this.components, components)) {
        this.components = components;
        this.$store.commit(Mutations.setHasChanges, true);
      }
    },
    updateTableConfig(tableConfig) {
      this.$store.commit(Mutations.setTableConfig, tableConfig);
    },
    excelExportComponent(component) {
      exportReport(this.report.name, {
        filterConfiguration: this.filterConfiguration,
        chartOptions: this.chartOptions,
        tableConfig: this.tableConfig,
        components: [component],
        format: "xlsx",
      });
    },
    editComponent(component) {
      this.addComponentModal.showAddComponentModal(this.components, this.components.indexOf(component));
    },
    updateComponent(component, index) {
      this.$set(this.components, index, component);
      this.$store.commit(Mutations.setHasChanges, true);
    },
    onReportConfigurationUpdated(config) {
      this.initialConfiguration = this.initialConfiguration || config;
      if (!_.isEqual(config, this.initialConfiguration) && !this.hasChanges) {
        this.$store.commit(Mutations.setHasChanges, true);
      }
    },
  },
  watch: {
    reportConfiguration: [{ deep: true, handler: "onReportConfigurationUpdated" }],
  },
});
</script>
