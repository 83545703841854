<template>
  <pill-list
    :title="$t('activerecord.models.team.other')"
    :typeahead="true"
    :items="items"
    :available-items="menuItems"
    @update="onUpdate($event)"
    @search="fetchTeams($event)"
  ></pill-list>
</template>

<script lang="ts">
import Vue, { defineComponent } from "vue";
import PillItem from "../interfaces/pill-item";
import MenuItem from "../interfaces/menu-item";
import pillList from "./pill-list.vue";
import TeamApiService from "../api/teams-api-service";

// interface Team {
//   id: string;
//   name: string;
// }

export default defineComponent({
  props: {
    teams: {
      type: Array,
      default: [],
    },
  },
  components: {
    pillList,
  },
  data() {
    return {
      menuTeams: [],
    };
  },
  computed: {
    items() {
      return this.teams.map(({ id, name }) => ({
        value: id,
        name,
      }));
    },
    menuItems() {
      return this.menuTeams.map(({ id, name }) => ({
        key: id,
        name,
      }));
    },
  },
  methods: {
    fetchTeams(search) {
      this.menuTeams = [];
      new TeamApiService().getTeams({ search }).then((teams) => (this.menuTeams = teams));
    },
    onUpdate({ items }) {
      const teams = items.map(({ value, name }) => ({
        id: value,
        name,
      }));
      this.$emit("update", teams);
    },
  },
});
</script>
