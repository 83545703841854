import Vue, { defineComponent } from "vue";
import store, { fetchInitialParameters } from "../store/store";
import router from "./router";
import UpdateTimesContainer from "../components/update-times-container.vue";
import UpdateTimesDropdown from "../components/update-times-dropdown.vue";
import ImportantMessage from "../components/important-message.vue";
import FlyoverFiltersContainer from "../flyover-filters/flyover-filters-container.vue";
import Sidenav from "../sidenav/sidenav.vue";
import ReportView from "../analytics/report-view.vue";
import CompanySelector from "../top-bar/company-selector.vue";
import LanguageSelector from "../top-bar/language-selector.vue";
import CurrencySelector from "../top-bar/currency-selector.vue";
import NewDashboardButton from "../components/new-dashboard-button.vue";
import NavbarTitle from "../navbar/navbar-title.vue";
import NavbarDropdownButton from "../components/navbar-dropdown-button.vue";
import AiAssistant from "@/ai-assistant/ai-assistant.vue";
import AiAssistantPlaceholder from "@/ai-assistant/ai-assistant-placeholder.vue";
import Menubar from "@/menubar/menubar.vue";
import MenubarMobile from "@/menubar/menubar-mobile.vue";

export default defineComponent({
  components: {
    UpdateTimesContainer,
    UpdateTimesDropdown,
    FlyoverFiltersContainer,
    Sidenav,
    ImportantMessage,
    ReportView,
    CompanySelector,
    LanguageSelector,
    CurrencySelector,
    NewDashboardButton,
    NavbarTitle,
    NavbarDropdownButton,
    AiAssistant,
    AiAssistantPlaceholder,
    Menubar,
    MenubarMobile,
  },
  router,
  store,
  template: "#vue-app-template",
  computed: {
    showFlyoverFilters() {
      const reportContext = this.$store.getters.getReportContext;
      return reportContext?.active_filters?.length > 0;
    },
    showAiAssistant() {
      return window.zoinedContext.aiAssistantEnabled;
    },
    vueAppClasses() {
      const classes = [];
      if (this.$store.state.flyoverFilters.open) {
        classes.push("flyover-filters-open");
      }
      return classes;
    },
    pageTitle() {
      return this.$route.meta?.title || this.$store.getters.pageTitle;
    },
  },
  created() {
    if (window.chart_token && !window.publicReportConfig) {
      return fetchInitialParameters();
    }
  },
  watch: {
    pageTitle: [{ immediate: true, handler: "onReportNameChange" }],
  },
  methods: {
    onReportNameChange() {
      const siteTitle = window.zoinedContext.siteTitle;
      if (this.pageTitle) {
        document.title = `${this.pageTitle} - ${siteTitle}`;
      } else {
        document.title = siteTitle;
      }
    },
  },
});
